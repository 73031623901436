import styled from "styled-components";
import { AutoComplete, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import Flex from "../../components/general/Flex";
import FlyIcon from "../../components/SvgComponents/FlyIcon";
import ShowLessIcon from "../../components/SvgComponents/ShowLessIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import useGetWarehouses from "../../hooks/warehouses/useWarehouses.hook.jsx";
import dayjs from "dayjs";
import { useGetPickupPoints } from "../../hooks/useGetPickupPoint.jsx";

const StyledOrdersTopActions = styled.div`
  background: white;
  border-radius: 12px;
  padding: 16px;

  .fly-sea {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    span.ant-select-selection-placeholder {
      padding-top: 5px;
    }
  }

  .show-less {
    background: transparent;
    border: none;
    outline: none;
  }

  .actions-btns {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 36px;

    button {
      margin: 0 0 0 8px;
      height: 36px;
      align-items: center;
      display: flex;
    }
  }

  .row {
    height: ${({ more }) => (more ? "100%" : " 52px")};
    overflow: hidden;
  }

  .mr-4 {
    margin-right: 4px;
  }

  &
    :where(.css-dev-only-do-not-override-1ipetx6).ant-select-multiple
    .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .show-more-icon {
    svg {
      transition: 200ms;
      transform: ${({ more }) => (more ? "rotate(0)" : "rotate(180deg)")};
    }
  }

  .ant-picker {
    width: 100%;
  }
`;

export default function TopActions({ setFilters }) {
  const [more, setMore] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const { usersList = [] } = usersAutocomplete(userInfo);
  const { data: warehouses = [] } = useGetWarehouses();
  const { data: pickupPointOptions = [] } = useGetPickupPoints();
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    let newValues = {
      ...values,
      created_at: values.created_at
        ? dayjs(values?.created_at).format("YYYY-MM-DD")
        : null,
      in_local_country_date: values.in_local_country_date
        ? dayjs(values?.in_local_country_date).format("YYYY-MM-DD")
        : null,
    };
    setFilters((p) => ({ ...newValues, is_found: p.is_found }));
  };

  const handleResetForm = () => {
    form.resetFields();
    setFilters((p) => ({ is_found: p.is_found }));
  };

  return (
    <StyledOrdersTopActions more={more}>
      <div>
        <Form
          labelCol={8}
          wrapperCol={4}
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          initialValues={{
            created_at: undefined,
          }}
        >
          <Row gutter={16} className="row">
            <Col lg={6}>
              <Form.Item name="user_info">
                <AutoComplete
                  style={{ height: 36 }}
                  placeholder="User name/code"
                  onSearch={(val) => {
                    setUserInfo({ user_info: val });
                  }}
                  onSelect={(_, val) => {
                    form.setFieldValue(
                      "user_info",
                      val?.label?.replaceAll("  ", " ")
                    );
                  }}
                  options={usersList}
                />
              </Form.Item>
            </Col>
            <Col lg={5}>
              <Form.Item name="tracking_code" rules={[{ min: 7 }]}>
                <Input placeholder="Tracking code" />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="warehouse_id">
                <Select
                  placeholder="All Warehouses"
                  style={{ height: 36 }}
                  options={warehouses}
                />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="dispatch_type">
                <Select
                  style={{ height: 36 }}
                  placeholder={
                    <Flex alignItems="center">
                      <FlyIcon />
                    </Flex>
                  }
                >
                  <Select.Option value="air">
                    <Flex alignItems={"center"}>
                      <FlyIcon />
                      <span style={{ paddingLeft: 5 }}>Air</span>
                    </Flex>
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Flex>
                <Form.Item name="price_min" className="mr-4">
                  <Input placeholder="Price min" />
                </Form.Item>
                <Form.Item name="price_max">
                  <Input placeholder="Price max" />
                </Form.Item>
              </Flex>
            </Col>{" "}
            <Col lg={3}>
              <Form.Item name="order_status">
                <Select
                  style={{ height: 36 }}
                  placeholder="Order Status"
                  defaultValue="Order Status"
                >
                  <Select.Option value="at_warehouse">
                    At Warehouse
                  </Select.Option>
                  <Select.Option value="on_the_way">On the way</Select.Option>
                  <Select.Option value="scan">Scanned</Select.Option>
                  <Select.Option value="in_local_country">
                    In Local Country
                  </Select.Option>
                  <Select.Option value="received">Received</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="row">
            <Col lg={4}>
              <Form.Item name="pickup_point_id">
                <AutoComplete
                  style={{ height: 36 }}
                  placeholder="Pickup Point"
                  options={pickupPointOptions}
                />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Flex>
                <Form.Item name="weight_min" className="mr-4">
                  <Input placeholder="Weight min" />
                </Form.Item>
                <Form.Item name="weight_max">
                  <Input placeholder="Weight max" />
                </Form.Item>
              </Flex>
            </Col>
            <Col lg={5}>
              <Flex>
                <Form.Item name="height" className="mr-4">
                  <Input placeholder="Height" />
                </Form.Item>
                <Form.Item name="width" className="mr-4">
                  <Input placeholder="Depth" />
                </Form.Item>
                <Form.Item name="length">
                  <Input placeholder="Length" />
                </Form.Item>
              </Flex>
            </Col>
            <Col lg={3}>
              <Form.Item name="parcel">
                <Input placeholder="Parcel name" />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="in_local_country_date">
                <DatePicker placeholder="In local country date" />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name={"created_at"}>
                <DatePicker placeholder="Created At" />
              </Form.Item>
            </Col>
          </Row>
          <div className="actions-btns">
            <button
              className="show-less"
              type="button"
              onClick={() => setMore(!more)}
            >
              <Flex alignItems="center">
                <div className="show-more-icon">
                  <ShowLessIcon />
                </div>
                <Typography
                  variant="span"
                  text={more ? "Show less" : "Show more"}
                  color="rgba(91, 109, 127, 1)"
                  padding="0 0 0 15px"
                />
              </Flex>
            </button>
            <ButtonSecondary text="Reset" click={() => handleResetForm()} />
            <ButtonPrimary
              text="Search"
              htmlType="submit"
              icon={<SearchIcon />}
            />
          </div>
        </Form>
      </div>
    </StyledOrdersTopActions>
  );
}
