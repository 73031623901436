import styled from "styled-components";
import { useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import TopActions from "./TopActions";
import useGetLostOrders from "../../hooks/lost-orders/useGetLostOrders.jsx";
import { Pagination, Table, Tabs } from "antd";
import Flex from "../../components/general/Flex.jsx";
import OnexTooltip from "../../helpers/tooltip.jsx";
import PrimeIcon from "../../components/SvgComponents/primeIcon.jsx";
import dayjs from "dayjs";
import ReadyIcon from "../../components/SvgComponents/ReadyIcon.jsx";
import XCircle from "../../components/SvgComponents/XCircle.jsx";

const StyledOrders = styled.div`
  padding-top: 24px;
  min-height: 100vh;

  .count {
    padding: 2px 10px;
    background-color: #eff8ea;
    border-radius: 16px;
    color: #5dba2f;
    margin-left: 8px;
  }

  .ant-table-thead > tr > th {
    white-space: nowrap;
    background: #f5f5f5;

    &:before {
      display: none;
    }
  }

  .table-wrapper {
    overflow: auto;
    margin-bottom: 24px;

    .ant-table-wrapper {
      width: ${({ full }) => (full ? "max-content" : "auto")};
    }
  }
`;
export default function LostOrders() {
  const [filters, setFilters] = useState({});
  const { orders, meta, isLoading, refetch } = useGetLostOrders(filters);
  const columns = [
    {
      key: "index",
      dataIndex: "index",
      title: " ",
      width: 39,
    },
    {
      key: "country",
      dataIndex: "country",
      title: "Country",
      width: 88,
    },
    {
      key: "user_name",
      dataIndex: "user_name",
      title: "User name",
      width: "289px",
    },
    {
      key: "tracking_code",
      dataIndex: "tracking_code",
      title: "Tracking code",
      width: 238,
    },
    {
      key: "declaration_price",
      dataIndex: "declaration_price",
      title: "Declaration Price",
      width: 140,
    },
    {
      key: "comment",
      dataIndex: "comment",
      title: "Comment",
      width: 199,
    },
    {
      key: "pickup_point",
      dataIndex: "pickup_point",
      title: "Pickup Point",
      width: 115,
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "Weight",
      width: 80,
    },
    {
      key: "dimensions",
      dataIndex: "dimensions",
      title: "Dimensions",
      width: 115,
    },
    {
      key: "in_local_country_date",
      dataIndex: "in_local_country_date",
      title: "In Local country date",
      width: 180,
    },
    {
      key: "parcel_name",
      dataIndex: "parcel_name",
      title: "Parcel Name",
      width: 125,
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: "Created At",
      width: 115,
    },
    {
      key: "found_at",
      dataIndex: "found_at",
      title: "Found At",
      width: 115,
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Status",
      width: 115,
    },
  ];

  const tableData = orders?.map((item, index) => ({
    index: (
      <Typography text={index + 1} variant="span" size="14px" weight="400" />
    ),
    country: (
      <Flex alignItems={"center"}>
        <OnexTooltip content={"Warehouse"} direction={"bottom"}>
          <img src={item?.warehouse?.round_flag_url || ""} alt="" />
        </OnexTooltip>
        <div style={{ paddingLeft: 8 }} id="dispatch">
          <img src={item?.dispatch?.icon_url || "-"} alt="" />
        </div>
      </Flex>
    ),
    user_name: (
      <Flex>
        <Typography
          text={`${item?.user?.full_name || "-"} ${
            item?.user?.user_code || "-"
          }`}
          variant="span"
          size="14px"
          weight="400"
          style={{ marginRight: "4px", whiteSpace: "nowrap" }}
        />
        {item?.user?.is_prime ? <PrimeIcon /> : <></>}
      </Flex>
    ),
    tracking_code: (
      <div>
        <Typography
          text={item?.order?.tracking_code || "-"}
          variant="span"
          size="14px"
          weight="400"
        />
        {item?.order_photo?.length && (
          <Typography
            style={{ textDecoration: "underline" }}
            text="Photo"
            variant="a"
            size="14px"
            weight="400"
            href={item?.order_photo0}
            color="#5B6D7F"
          />
        )}
      </div>
    ),
    declaration_price: (
      <Typography
        text={item?.order?.declaration_price || "-"}
        variant="span"
        size="14px"
        weight="400"
      />
    ),
    comment: (
      <Typography
        text={item?.order?.customer_comment || "-"}
        variant="span"
        size="12px"
        weight="400"
        color="#5B6D7F"
      />
    ),
    pickup_point: (
      <Typography
        text={item?.order?.pickup_point || "-"}
        variant="span"
        size="14px"
        weight="400"
        style={{ whiteSpace: "nowrap" }}
      />
    ),
    weight: (
      <Typography
        text={item?.order?.weight || "-"}
        variant="span"
        size="14px"
        weight="400"
      />
    ),
    dimensions: (
      <Typography
        text={`${item?.order?.height || "0"}x${item?.order?.width || "0"}x${
          item?.order?.length
        }`}
        variant="span"
        size="14px"
        weight="400"
      />
    ),
    in_local_country_date: (
      <Typography
        text={
          dayjs(item?.order?.in_local_country_date).format("DD.MM.YYYY") || "-"
        }
        variant="span"
        size="14px"
        weight="400"
        color="#5B6D7F"
      />
    ),
    parcel_name: (
      <Typography
        text={item?.order?.parcel || "-"}
        variant="span"
        size="14px"
        weight="400"
        style={{ whiteSpace: "nowrap" }}
      />
    ),
    created_at: (
      <Typography
        text={dayjs(item?.created_at).format("DD.MM.YYYY") || "-"}
        variant="span"
        size="14px"
        weight="400"
        color="#5B6D7F"
      />
    ),
    found_at: (
      <Typography
        text={item?.found_at ? dayjs(item?.found_at).format("DD.MM.YYYY") : "-"}
        variant="span"
        size="14px"
        weight="400"
        color="#5B6D7F"
      />
    ),
    status: (
      <Flex alignItems="center">
        {item?.order?.is_ready ? (
          <>
            <ReadyIcon />
            <Typography
              variant="p"
              text="Ready"
              color="#5DBA2F"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </>
        ) : (
          <>
            <XCircle />
            <Typography
              variant="p"
              text="Not Ready"
              color="#FC4447"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
              style={{ whiteSpace: "nowrap" }}
            />
          </>
        )}
      </Flex>
    ),
  }));
  return (
    <StyledOrders className="orders" full={orders?.length > 0}>
      <div className="page-title">
        <Typography
          text="Lost Orders"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <TopActions setFilters={setFilters} />
      <Tabs
        onChange={(key) => setFilters((p) => ({ ...p, is_found: key }))}
        items={[
          {
            key: null,
            label: (
              <Flex>
                All <span className="count">{meta?.options?.total || 0}</span>
              </Flex>
            ),
          },
          {
            key: 0,
            label: (
              <Flex>
                Not found{" "}
                <span className="count">
                  {meta?.options?.total_not_founds || 0}
                </span>
              </Flex>
            ),
          },
          {
            key: 1,
            label: (
              <Flex>
                Found{" "}
                <span className="count">
                  {meta?.options?.total_founds || 0}
                </span>
              </Flex>
            ),
          },
        ]}
      />
      <Flex style={{ overflow: "auto", width: "100%" }}>
        <Table
          columns={columns || []}
          loading={isLoading}
          dataSource={tableData}
          pagination={false}
        />
      </Flex>

      <div
        className={"new-pagination"}
        style={{ padding: 0, marginTop: "16px" }}
      >
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
    </StyledOrders>
  );
}
